import React, { useState, useEffect } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Modal, Table } from 'components';
import { ago } from 'utils/formatters';
import { ChartPieIcon } from '@heroicons/react/solid';
import ModalHeader from 'components/ModalHeader';
import PieChart from './Charts/PieChart';

const LeadSourcesTable = ({ selectedDates }) => {
  const { sourcesPaginationParams } = useSelector;
  const { params, updateParams, sortParam } = useQueryParams({
    ...sourcesPaginationParams,
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState({});
  const [sourceStatus, setSourceStatus] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  const getSource = async () => {
    setLoading(true);
    await axios
      .get(`agency/leads/source`, {
        params: {
          ...params,
          startDateStr: selectedDates.selectedStartDate,
          endDateStr: selectedDates.selectedEndDate,
        },
      })
      .then((res) => {
        setSource(res.data.data);
      });
    setLoading(false);
  };

  const getStatuses = async () => {
    setLoading(true);
    await axios.get(`agency/leads/source/${selectedId}`).then((res) => {
      setSourceStatus(res.data?.data?.leadsStatus);
    });
    setLoading(false);
  };

  //intial param
  useEffect(() => {
    updateParams({
      page: 1,
      pageSize: 100,
      sort: 'createdAt:desc',
    });
  }, []);

  useEffect(() => {
    async function getData() {
      await getSource();
      // await getStatuses();
    }
    getData();
  }, [params, selectedDates]);

  useEffect(() => {
    if (selectedId) {
      async function getData() {
        await getStatuses();
        // await getStatuses();
      }
      getData();
    }
  }, [selectedId]);

  const handleSelect = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleDownloadCSV = async (leadSourceId) => {
    try {
      const response = await axios.get(
        `agency/leads/source/${leadSourceId}/export`,
        {
          responseType: 'blob', // Important for handling file downloads
        }
      );

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `leads-source-${leadSourceId}.csv`);
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const renderPercentage = (count, total) => {
    if (count > 0) {
      const percentage = ((count / total) * 100).toFixed(2);
      return (
        <>
          {count}
          <span
            className={`px-4 ${
              percentage > 1 ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {percentage}%
          </span>
        </>
      );
    }
    return 0;
  };

  return (
    <>
      {/* source{JSON.stringify(source, null, 2)} */}
      {source.count > 0 ? (
        <div className="h-full flex flex-col">
          <div className="relative flex-1 overflow-hidden">
            <div className="absolute inset-0 overflow-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 z-10">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-white sticky left-0 z-20"
                    >
                      Uploaded By
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-white sticky left-[200px] z-20"
                    >
                      Lead Source ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-white sticky left-[400px] z-20"
                    >
                      File Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total Rows
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Inserted Rows
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Uploaded Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Unprocessed New Lead
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Archived
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ready To Pitch
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Pitched
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Accepted
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Booked
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {source?.rows?.map((row) => (
                    <tr
                      key={row.leadSourceId}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 sticky left-0 bg-white">
                        {row.uploadedByUser
                          ? `${row.uploadedByUser.firstName} ${row.uploadedByUser.lastName}`
                          : ''}
                      </td>
                      <td className="px-6 py-4 sticky left-[200px] bg-white">
                        <button
                          onClick={() => handleDownloadCSV(row.leadSourceId)}
                          className="text-blue-600 hover:text-blue-800 hover:underline"
                        >
                          {row.leadSourceId}
                        </button>
                      </td>
                      <td className="px-6 py-4 sticky left-[400px] bg-white">
                        {row.filename}
                      </td>
                      <td className="px-6 py-4">{row.totalRows}</td>
                      <td className="px-6 py-4">{row.inserted}</td>
                      <td className="px-6 py-4">{ago(row.createdAt)}</td>
                      <td className="px-6 py-4">
                        {renderPercentage(
                          Number(row.unprocessedCount),
                          row.inserted
                        )}
                      </td>
                      <td className="px-6 py-4">
                        {renderPercentage(
                          Number(row.archivedCount),
                          row.inserted
                        )}
                      </td>
                      <td className="px-6 py-4">
                        {renderPercentage(Number(row.rtpCount), row.inserted)}
                      </td>
                      <td className="px-6 py-4">
                        {renderPercentage(
                          Number(row.pitchedCount),
                          row.inserted
                        )}
                      </td>
                      <td className="px-6 py-4">
                        {renderPercentage(
                          Number(row.acceptedCount),
                          row.inserted
                        )}
                      </td>
                      <td className="px-6 py-4">
                        {renderPercentage(
                          Number(row.bookedCount),
                          row.inserted
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-full py-6">
          <p>Nothing to display</p>
        </div>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-3/5 h-3/4 my-8 overflow-hidden text-left transition-all rounded-xl transform bg-white shadow-xl">
          <ModalHeader
            title={'Lead Source Metrics'}
            setOpen={setOpen}
            titleClasses="capitalize"
            border="border-0"
            px="px-8"
            py="py-4"
            fontSize="text-xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            onClose={() => setOpen(false)}
          />
          <PieChart title="Report by status" data={sourceStatus} />
        </div>
      </Modal>
    </>
  );
};

export default LeadSourcesTable;
